// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore/lite'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBBMz-Hbhj7R1y7IHQIPz8PKmkiLpSbN40",
  authDomain: "franklin-code.firebaseapp.com",
  projectId: "franklin-code",
  storageBucket: "franklin-code.appspot.com",
  messagingSenderId: "546576069709",
  appId: "1:546576069709:web:b168c119045185d16f5804",
  measurementId: "G-HCRQ1XVYYL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const config = getFirestore(app)
const analytics = getAnalytics(app);  

export default config;
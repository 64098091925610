const global = {
    //Social media
    instagram: "https://www.instagram.com/franklinpimentel/",
    facebook: "https://www.facebook.com/franklin.pimentel/",
    email: "franklin.pimentel.1998@gmail.com",
    linkedin: "https://www.linkedin.com/in/franklin-pimentel-a28419194/",
    github: "https://github.com/Franklingp/",
    behance: "https://www.behance.net/franklingp/",
    twitter: "https://twitter.com/franklin_gp/"
}

export default global;